import React from "react";
import "./label.css";

const Label = ({ label, htmlFor }) => {
  return (
    <label className="at_label" htmlFor={htmlFor}>
      {label}
    </label>
  );
};

export default Label;
