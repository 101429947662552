import React from "react";
import Label from "../Label/Label";
import { DatePicker } from "antd";
import "./dateTime.css";

const DateTime = ({ value, onChange, label, id }) => {
  return (
    <div>
      <Label label={label} htmlFor={id} />
      <DatePicker
        format="DD/MM/YYYY hh:mm A"
        onChange={(date, dateString) => onChange(date)}
        showTime={{ use12Hours: true }}
        className={"at_datepicker"}
        value={value}
        id={id}
      />
    </div>
  );
};

export default DateTime;
