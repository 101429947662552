import React, { useState, useEffect } from "react";
import ReactSelect, { createFilter } from "react-select";
import Label from "../Label/Label";
import "./select.css";

const Select = ({ label, style, ...restProps }) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (restProps.showSelectAll && restProps.options?.length > 0) {
      if (restProps.options?.length === restProps.value?.length) {
        setChecked(true);
      } else {
        setChecked(false);
      }
    }
  }, [restProps.options, restProps.value]);

  const onChange = ({ target: { checked } }) => {
    setChecked(checked);
    restProps.onChange(restProps.options);
  };
  return (
    <div className="mb1">
      <Label label={label} htmlFor={restProps.inputId} />
      <ReactSelect
        classNamePrefix="react-select-al"
        filterOption={createFilter({
          matchFrom: "any",
          stringify: (option) => `${option.label}`,
        })}
        styles={{
          control: (provided, state) => ({
            ...provided,
            cursor: state.isDisabled ? "not-allowed" : "default",
          }),
          container: (provided) => ({
            ...provided,
            pointerEvents: "initial",
          }),
          clearIndicator: (provided, state) => ({
            ...provided,
            color: state.isFocused ? "#a30000" : "#a30000",
            cursor: "pointer",
            fontWeight: 500,
          }),
          multiValue: (base) => ({
            ...base,
            "background-color": "#ffffff",
            border: "1px solid hsl(0, 0%, 90%)",
          }),
          ...(style || {}),
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        }}
        menuPosition={"fixed"}
        menuPortalTarget={document.body}
        {...restProps}
      />
      {restProps.showSelectAll && (
        <div className="flex al-ctr delSelect_select-all-wrapper">
          <div className="delSelect_select-all">select all</div>
          <input type="checkbox" onChange={onChange} checked={checked} />
        </div>
      )}
    </div>
  );
};

export default Select;
