import React, { useEffect, useState, useContext } from "react";
import { SWRConfig } from "swr";
import YourSvg from "./assets/images/Delpheon-AT logo.svg";
import NotyfContext from "./lib/contexts/NotyfContext";
import Annotation from "./pages/Annotation";
import "./index.css";
import fetcher from "./lib/delApi";

const options = {
  fetcher,
  shouldRetryOnError: false,
  revalidateOnFocus: false,
};

function App() {
  const [notyf, setNotyf] = useState(null);

  useEffect(() => {
    import("notyf").then(({ Notyf }) => {
      setNotyf(
        new Notyf({
          duration: 5000,
          dismissible: true,
          position: { x: "right", y: "top" },
          types: [
            { type: "success", background: "hsl(170, 91%, 32%)" },
            {
              type: "warning",
              background: "#ffe827",
              className: "del_warning-toast-wrapper",
            },
          ],
        })
      );
    });
  }, []);

  return (
    <NotyfContext.Provider value={notyf}>
      <SWRConfig value={options}>
        <div className="annotationTool">
          <header className="header-annotationTool">
            <div className="main">
              <img src={YourSvg} alt="app logo" />
              <div className="nav-list">
                <div className="nav-item active">Annotation</div>
                {/* <div className="nav-item">View</div>
                <div className="nav-item">About</div> */}
              </div>
            </div>
          </header>
          <div className="main">
            <Annotation />
          </div>
        </div>
      </SWRConfig>
    </NotyfContext.Provider>
  );
}

export default App;
