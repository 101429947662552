import React from "react";
import { Modal } from "antd";
const ATModal = (props) => {
  return (
    <Modal
      {...props}
      okButtonProps={{
        className: "at_button ",
        ...props.okButtonProps,
      }}
    >
      {props.open && props.children}
    </Modal>
  );
};

export default ATModal;
