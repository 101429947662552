import React, { useEffect, useRef, useState } from "react";
import useSWR from "swr";
import DateTime from "../components/DateTime/DateTime";
import ReactSelect from "../components/Select/Select";
import { delApi } from "../lib/delApi";
import {
  getNodesByType,
  getTotalMinutesBetweenEpochs,
  transformResponseData,
} from "../lib/utils/helper";
import ReactECharts from "echarts-for-react";
import Button from "../components/Button";
import { AUTO_FILL_PARAMS_WITH_UNIT } from "../lib/utils/constants";
import Modal from "../components/Modal";
import Spinner from "../lib/utils/Spinner";

const Annotation = () => {
  const [stationList, setStationList] = useState([]);
  const [assetsList, setassetsList] = useState([]);
  const [parameterList, setParameterList] = useState([]);
  const [filter, setFilter] = useState({});
  const [brushedTimeRange, setBrushedTimeRange] = useState(null);
  const [shadedArea, setShadedArea] = useState([]);
  const [annotationSaveModal, setAnnotationSaveModal] = useState({});
  const [headers] = useState({
    applicationID: sessionStorage.getItem("appId"),
    companyName: localStorage.getItem("companyName"),
    companyID: localStorage.getItem("companyId"),
  });
  const [isLoading, setIsLoading] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    handleSave();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setAnnotationSaveModal({});
  };

  const [response, setResponse] = useState(null);
  const rawResponse = useRef(null);

  const { data: hierarchy, error: hierarchyError } = useSWR([
    headers?.companyName
      ? `/twin/api/Hierarchy/${encodeURIComponent(
          headers.companyName
        )}/${sessionStorage.getItem("appName")}`
      : null,
    headers,
  ]);

  const { data: labels, error: lablesError } = useSWR([
    headers?.companyName ? `/annotation/api/annotation/label` : null,
    headers,
  ]);

  const getShadedArea = async () => {
    const res = await delApi(
      `/annotation/api/annotation/getannotations`,
      "POST",
      {
        start_time: filter.startTime?.valueOf(),
        end_time: filter.endTime?.valueOf(),
        nodeIds: filter.assets.map((asset) => asset.value),
      },
      null,
      null,
      headers
    );
    if (res) {
      setShadedArea(res || []);
    }
  };

  useEffect(() => {
    if (filter.startTime && filter.endTime && filter.assets) {
      getShadedArea();
    }
  }, [filter.assets, filter.startTime, filter.endTime]);

  useEffect(() => {
    if (hierarchy) {
      const stationList = getNodesByType(hierarchy, "Level 5");
      setStationList(stationList);
    }
  }, [hierarchy]);

  const handleLoadChart = async () => {
    setIsLoading(true);
    const res = await delApi(
      `/parameters/telemetry/rawdata/all`,
      "POST",
      {
        derivedParameters: [],
        basicParameters: [...filter.parameters?.map((param) => param.value)],
        starttime: filter.startTime?.valueOf(),
        endtime: filter.endTime?.valueOf(),
        groupby: "",
        fillnull: true,
      },
      "Data Fetched",
      null,
      headers
    );
    if (res) {
      rawResponse.current = res;
      setResponse(
        transformResponseData(
          {
            derivedParameters: [],
            basicParameters: [
              ...filter.parameters?.map((param) => param.value),
            ],
            starttime: filter.startTime?.valueOf(),
            endtime: filter.endTime?.valueOf(),
            groupby: "",
            fillnull: true,
          },
          res
        )
      );
    }
    setIsLoading(false);
  };

  const options = {
    grid: { top: 30, right: 30, bottom: 80, left: 50 },
    xAxis: {
      type: "time",
    },
    yAxis: {
      type: "value",
    },
    brush: {
      toolbox: ["lineX", "clear"], // Add brush toolbox for rectangular selection and clearing
      xAxisIndex: 0, // Enable brushing along the x-axis
      brushType: "lineX", // Use rectangular brushing
    },
    dataZoom: [
      {
        type: "slider", // Slider control for zooming
        xAxisIndex: 0, // Applies to the x-axis
        start:
          100 -
          (60 / getTotalMinutesBetweenEpochs(filter.startTime, filter.endTime) <
          1
            ? (60 /
                getTotalMinutesBetweenEpochs(
                  filter.startTime,
                  filter.endTime
                )) *
              100
            : 100), // Initial range (percentage)
        end: 100, // Initial range (percentage)
      },
      {
        type: "inside", // Enables zooming by scrolling
        xAxisIndex: 0, // Applies to the x-axis
      },
    ],
    series: [
      ...(Object.entries(response || [])?.map(([key, value], index) => ({
        data: response ? response[key] : [],
        type: "line",
        smooth: false,
        name: filter.parameters?.find((param) => param?.value === key)?.label,
        markArea:
          index === 0
            ? {
                data: [
                  ...(shadedArea?.flat(1).map((item, i) => [
                    {
                      name: item.name,
                      coord: [item.starttime, null],
                      itemStyle: {
                        color: "rgba(135, 206, 250, 0.2)", // Light blue with transparency
                      },
                      label: { show: true },
                    },
                    {
                      coord: [item.endtime, null],
                    },
                  ]) || []),
                ],
              }
            : {},
      })) || []),
    ],

    tooltip: {
      trigger: "axis",
    },
  };

  console.log("shaed area", {
    data: [
      ...(shadedArea?.flat(1).map((item, i) => [
        {
          name: item.name,
          coord: [item.starttime, null],
          itemStyle: {
            color: "rgba(135, 206, 250, 0.2)", // Light blue with transparency
          },
          label: { show: false },
        },
        {
          coord: [item.endtime, null],
        },
      ]) || []),
    ],
  });

  const getSmallestGreaterOrEqual = (array, target) => {
    console.log(array, target);
    // Filter out numbers greater than or equal to the target and get the minimum
    const filteredArray = array.filter((num) => num >= target);
    if (filteredArray.length === 0) {
      return null; // No number satisfies the condition
    }
    return Math.min(...filteredArray);
  };

  const getLargestSmallerOrEqual = (array, target) => {
    console.log(array, target);
    // Filter out numbers greater than or equal to the target and get the minimum
    const filteredArray = array.filter((num) => num <= target);
    if (filteredArray.length === 0) {
      return null; // No number satisfies the condition
    }
    return Math.max(...filteredArray);
  };

  const onChartReady = (echartsInstance) => {
    // Add a listener for the brushSelected event
    echartsInstance.on("brushEnd", (params) => {
      console.log(params);
      console.log(rawResponse.current);
      const startTimestamp = params?.areas[0]?.coordRange[0] || 0;
      const endTimestamp = params?.areas[0]?.coordRange[1] || 0;

      const timeStamps = rawResponse.current?.map((item) => item.unixtime);
      console.log(timeStamps);
      setBrushedTimeRange([
        getSmallestGreaterOrEqual(timeStamps, startTimestamp),
        getLargestSmallerOrEqual(timeStamps, endTimestamp),
      ]);

      console.log("startTime", startTimestamp, "endTime", endTimestamp);
    });
  };

  console.log(brushedTimeRange);

  const handleAnnotate = () => {
    showModal();
  };

  const handleSave = async () => {
    console.log("Clicked!!");

    const res = await delApi(
      `/annotation/api/annotation/save`,
      "POST",
      {
        StationId: filter.station.value,
        StartTime: brushedTimeRange[0],
        EndTime: brushedTimeRange[1],
        PrimaryAssetId: annotationSaveModal.primaryAsset?.value,
        AssociatedAssetId:
          annotationSaveModal.associatedAsset?.map((as) => as.value) || [],
        LabelId: annotationSaveModal?.label?.value,
      },
      "Annotation Saved Successfully",
      null,
      headers
    );
    if (res) {
      setIsModalOpen(false);
      setAnnotationSaveModal({});
    }
  };
  console.log("filter: ", filter);

  useEffect(() => {
    setIsLoading(null);
    setBrushedTimeRange(null);
    setAnnotationSaveModal({});
  }, [filter]);

  useEffect(() => {
    if (annotationSaveModal.label?.value) {
      console.log("cccc");
      const primaryAssetOptions = filter.assets?.filter(
        (asset) => asset.parent === annotationSaveModal?.label?.PrimaryAssetType
      );

      if (primaryAssetOptions?.length === 1) {
        setAnnotationSaveModal((prev) => ({
          label: prev.label,
          primaryAsset: primaryAssetOptions[0],
        }));
      }
    }
  }, [annotationSaveModal.label?.value]);

  return (
    <div className="mt_20">
      <div className="flex flex-gap-20">
        <div className={"flex-1"}>
          <ReactSelect
            label={"Station"}
            inputId={`select_station}`}
            placeholder={`Select Station`}
            value={filter.station}
            options={stationList?.map((station) => ({
              label: station.name,
              value: station.id,
              details: station,
            }))}
            onChange={(selection) => {
              if (selection.value !== filter.station?.value) {
                setFilter({ station: selection });
                setassetsList([]);
                setParameterList([]);
              }
            }}
          />
        </div>
        <div className={"flex-1"}>
          <ReactSelect
            isMulti
            label={"Asset Type"}
            inputId={`select_assetTypes}`}
            placeholder={`Select Asset Types`}
            value={filter.assetTypes || null}
            options={
              filter.station
                ? filter.station?.details?.children?.map((assetType) => ({
                    label: assetType.name,
                    value: assetType.id,
                    details: assetType,
                  }))
                : []
            }
            onChange={(selections) => {
              const newSelection = selections.filter(
                (item) =>
                  !filter.assetTypes?.map((a) => a.label).includes(item.label)
              );

              const newDeletion =
                filter.assetTypes?.filter(
                  (item) =>
                    !selections?.map((a) => a.label)?.includes(item.label)
                ) || [];

              console.log("aaaaaaa", newSelection, newDeletion);

              const newAssetList =
                (newSelection.length
                  ? filter.assets
                  : newDeletion.length
                  ? filter.assets?.filter(
                      (asset) =>
                        !newDeletion
                          ?.map((at) => at.label)
                          ?.includes(asset.parent)
                    )
                  : filter.assets) || [];

              //   const newAssetSelection = newAssetList?.filter(
              //     (item) =>
              //       !filter.assets?.map((a) => a.value)?.includes(item.value)
              //   );

              const newAssetDeletion =
                filter.assets?.filter(
                  (item) =>
                    !newAssetList?.map((a) => a.value)?.includes(item.value)
                ) || [];

              console.log("bbbbbbb", newAssetDeletion);

              const newParameterList = newAssetDeletion.length
                ? filter.parameters?.filter(
                    (param) =>
                      !newAssetDeletion
                        ?.map((at) => at.value)
                        ?.includes(param.parent)
                  )
                : filter.parameters;

              //   const newparameterList = newAssetSelection?.length
              //     ? filter.parameters
              //     : newAssetDeletion.length
              //     ? filter.assets?.filter(
              //         (asset) =>
              //           !newDeletion
              //             ?.map((at) => at.label)
              //             ?.includes(asset.parent)
              //       )
              //     : filter.assets;

              setFilter((prev) => ({
                ...prev,
                assetTypes: selections,
                assets: newAssetList,
                parameters: newParameterList,
              }));
              setassetsList(
                selections?.reduce((acc, curr = []) => {
                  return [
                    ...acc,
                    ...(curr.details?.children?.map((child) => ({
                      ...child,
                      type: curr.details.name,
                    })) || []),
                  ];
                }, [])
              );
              const allParameters = newAssetList.reduce(
                (acc, curr) => [
                  ...acc,
                  ...(curr.details?.parameters?.map((param) => ({
                    ...param,
                    parent: curr.value,
                  })) || []),
                ],
                []
              );
              setParameterList(allParameters);
            }}
          />
        </div>
      </div>
      <ReactSelect
        isMulti
        label={"Asset Name"}
        inputId={`select_assets}`}
        placeholder={`Select Assets`}
        value={filter.assets || null}
        options={
          filter.assetTypes
            ? assetsList?.map((asset) => ({
                label: asset.name,
                value: asset.id,
                details: asset,
                parent: asset.type,
              }))
            : []
        }
        onChange={(selections) => {
          const allParameters = selections.reduce(
            (acc, curr) => [
              ...acc,
              ...(curr.details?.parameters?.map((param) => ({
                ...param,
                parent: curr.value,
              })) || []),
            ],
            []
          );
          const newSelection = selections.filter(
            (item) => !filter.assets?.map((a) => a.value)?.includes(item.value)
          );

          const newDeletion = filter.assets?.filter(
            (item) => !selections?.map((a) => a.value)?.includes(item.value)
          );

          console.log(newSelection);
          console.log(newDeletion);
          setParameterList(allParameters);

          setFilter((prev) => ({
            ...prev,
            assets: selections,
            parameters: newSelection.length
              ? [
                  ...(prev.parameters || []),
                  ...(newSelection[0].details?.parameters
                    ?.filter((param) =>
                      AUTO_FILL_PARAMS_WITH_UNIT.includes(param.Unit)
                    )
                    ?.map((parameter) => ({
                      label: parameter.ParameterFriendlyName,
                      value: parameter.ParameterID,
                      details: parameter,
                      parent: newSelection[0]?.value,
                    })) || []),
                ]
              : [
                  ...(prev.parameters?.filter(
                    (param) =>
                      !newDeletion?.map((a) => a.value)?.includes(param.parent)
                  ) || []),
                ],
          }));
        }}
      />
      <ReactSelect
        isMulti
        label={"Parameter Name"}
        inputId={`select_parameters}`}
        placeholder={`Select Parameters`}
        value={filter.parameters || null}
        options={parameterList.map((parameter) => ({
          label: parameter.ParameterFriendlyName,
          value: parameter.ParameterID,
          details: parameter,
          parent: parameter.parent,
        }))}
        onChange={(selections) => {
          setFilter((prev) => ({
            ...prev,
            parameters: selections,
          }));
        }}
      />
      <div className="flex flex-gap-20 al-end">
        <DateTime
          label={"From"}
          value={filter.startTime}
          id={"startTime"}
          onChange={(value) =>
            setFilter((prev) => ({ ...prev, startTime: value }))
          }
        />
        <DateTime
          label={"To"}
          value={filter.endTime}
          id={"endTime"}
          onChange={(value) =>
            setFilter((prev) => ({ ...prev, endTime: value }))
          }
        />
        {filter.endTime && filter.startTime && filter.parameters?.length > 0 && (
          <Button
            onClick={handleLoadChart}
            title={
              (filter.endTime && filter.startTime && filter.parameters) ||
              "Please fill all the inputs"
            }
          >
            Load Chart
          </Button>
        )}
      </div>
      {options && (
        <div className="mt_50">
          {isLoading === null ? (
            <p>Please add all the filters.</p>
          ) : isLoading ? (
            <Spinner />
          ) : (
            <>
              <ReactECharts option={options} onChartReady={onChartReady} />
              {brushedTimeRange && (
                <div className="flex just-end mt_20">
                  <Button className={"mt_20"} onClick={handleAnnotate}>
                    Annotate
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
      )}

      <Modal
        title="Save Annotation"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={"Save"}
        okButtonProps={{
          disabled: !(
            annotationSaveModal?.label?.value &&
            annotationSaveModal?.primaryAsset?.value
          ),
        }}
      >
        {
          <>
            <ReactSelect
              label={"Label"}
              inputId={`select_label}`}
              placeholder={`Select Label`}
              value={annotationSaveModal.label || null}
              options={labels
                ?.filter((label) =>
                  filter?.assets?.some(
                    (asset) => asset.details.type === label.PrimaryAssetType
                  )
                )
                ?.map((label) => ({
                  label: label.LabelName,
                  value: label.LabelId,
                  PrimaryAssetType: label.PrimaryAssetType,
                  AssociatedAssetType: label.AssociatedAssetType,
                }))}
              onChange={(selection) => {
                setAnnotationSaveModal({
                  label: selection,
                  primaryAsset: null,
                  associatedAsset: null,
                });
              }}
            />
            <ReactSelect
              label={"Primary Asset"}
              inputId={`select_label}`}
              placeholder={`Select Primary Asset`}
              value={annotationSaveModal.primaryAsset || null}
              options={
                annotationSaveModal?.label
                  ? filter.assets?.filter(
                      (asset) =>
                        asset.parent ===
                        annotationSaveModal?.label?.PrimaryAssetType
                    )
                  : []
              }
              onChange={(selection) => {
                setAnnotationSaveModal((prev) => ({
                  ...prev,
                  primaryAsset: selection,
                  associatedAsset: [],
                }));
              }}
            />

            {filter.assets?.filter(
              (asset) =>
                (asset.parent ===
                  annotationSaveModal?.label?.PrimaryAssetType &&
                  annotationSaveModal?.primaryAsset?.value !== asset.value) ||
                annotationSaveModal?.label?.AssociatedAssetType?.includes(
                  asset.parent
                )
            )?.length > 0 && (
              <ReactSelect
                isMulti
                label={"Associated Asset"}
                inputId={`select_associated_asset}`}
                placeholder={`Select Associated Asset`}
                value={annotationSaveModal.associatedAsset || null}
                options={
                  annotationSaveModal?.label
                    ? filter.assets
                        ?.filter(
                          (asset) =>
                            asset.parent ===
                              annotationSaveModal?.label?.PrimaryAssetType ||
                            annotationSaveModal?.label?.AssociatedAssetType?.includes(
                              asset.parent
                            )
                        )
                        ?.filter(
                          (asset) =>
                            asset.value !==
                            annotationSaveModal.primaryAsset?.value
                        )
                    : []
                }
                onChange={(selections) => {
                  setAnnotationSaveModal((prev) => ({
                    ...prev,
                    associatedAsset: selections,
                  }));
                }}
              />
            )}
          </>
        }
      </Modal>
    </div>
  );
};

export default Annotation;
