import { useCallback, useState, useEffect } from "react";

export function searchTree(element, id) {
  if (element.id === id) {
    return element;
  } else if (element.children != null) {
    var i;
    var result = null;
    for (i = 0; result == null && i < element.children.length; i++) {
      result = searchTree(element.children[i], id);
    }
    return result;
  }
  return null;
}

export function getParent(data, id, ancestor) {
  var node;

  data.some(function (n) {
    if (n.id === id) {
      node = ancestor ? ancestor : null;
      return node;
    }
    if (n.children) {
      return (node = getParent(n.children, id, n));
    }
  });
  return node || null;
}

export function usePathBuilder(data) {
  const [pathList, setPathList] = useState([]);

  useEffect(() => {
    if (data) {
      const result = [data].map(pathBuilder());
      result?.length > 0 && setPathList(result[0]);
    }
  }, [data]);

  return pathList;
}

export function pathBuilder(path, acc = []) {
  path = path || "";
  return function (o) {
    let pathObj = {
      name: path.concat(
        o.type !== "root" ? " - " + (o.name || o.Name) : o.name || o.Name
      ),
      id: o.id,
    };
    acc.push(pathObj);
    if (o.children?.length > 0) {
      o.children.map(pathBuilder(pathObj.name, acc));
    }
    return acc;
  };
}

export function usePathIdBuilder(data) {
  const [pathList, setPathList] = useState([]);

  useEffect(() => {
    if (data) {
      const result = [data].map(pathIdBuilder());
      result?.length > 0 && setPathList(result[0]);
    }
  }, [data]);

  return pathList;
}

export function pathIdBuilder(path, acc = []) {
  path = path || "";
  return function (o) {
    let pathObj = {
      name: path.concat(o.type !== "root" ? " - " + o.id : o.id),
      id: o.id,
    };
    acc.push(pathObj);
    if (o.children?.length > 0) {
      o.children.map(pathIdBuilder(pathObj.name, acc));
    }
    return acc;
  };
}

export function getLevelNodeCounts(data, key, level = 0, arr = []) {
  if (data == null) return;

  arr.push({ level, count: data[key]?.length || 0 });

  if (data[key] && data[key].length > 0) {
    data[key].forEach((d) => getLevelNodeCounts(d, key, level + 1, arr));
  }

  var holder = {};

  arr.forEach(function (d) {
    if (holder.hasOwnProperty(d.level)) {
      holder[d.level] = holder[d.level] + d.count;
    } else {
      holder[d.level] = d.count;
    }
  });

  var res = [];

  for (var prop in holder) {
    res.push({ level: +prop, count: holder[prop] });
  }

  return res;
}

export const clearError = (porpName, errorState, setErrorSate) => {
  const _errorState = { ...errorState };
  delete _errorState[porpName];
  setErrorSate(_errorState);
};

export const isNumber = (value) => {
  return !isNaN(value);
};

export const capitalizeFirstLetter = (string) => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};

export const dataToLowerCase = (value) => {
  return value.toLowerCase();
};

// Function to get nodes with a specific name from the tree
export const getNodesByType = (tree, targetType, result = []) => {
  if (tree.type === targetType) {
    result.push(tree);
  }

  if (tree.children && tree.children.length > 0) {
    for (const child of tree.children) {
      getNodesByType(child, targetType, result);
    }
  }

  return result;
};

export const transformResponseData = (payload, data) => {
  let actualBody = payload;
  const timegap = 1000 * 60 * 15;
  let completeList = [
    ...actualBody.basicParameters,
    ...actualBody.derivedParameters,
  ];
  let newData = [];
  if (data.length === 0) {
    return [];
  }
  //start of new codee

  let transformedData = {};
  completeList.forEach((item) => {
    transformedData[item] = [];
  });
  for (let i = 0; i < data.length; i++) {
    data[i].parameters.forEach((item) => {
      transformedData[item.parameterId].push([
        data[i].unixtime,
        isNaN(item.reading) ? null : Math.round(item.reading * 100) / 100,
      ]);
    });
  }
  // return json(transformedData);
  if (actualBody.fillnull) {
    Object.keys(transformedData).forEach((key) => {
      let newArr = [];
      let oldArr = transformedData[key];
      for (let j = 0; j < oldArr.length; j++) {
        newArr.push(oldArr[j]);
        if (
          j !== oldArr.length - 1 &&
          oldArr[j + 1][0] - oldArr[j][0] > timegap
        ) {
          newArr.push([
            oldArr[j][0] + (oldArr[j + 1][0] - oldArr[j][0]) / 2,
            null,
          ]);
        }
      }
      if (newArr.length) {
        if (newArr[0][0] !== actualBody.starttime) {
          newArr = [[actualBody.starttime, null], ...newArr];
        }
        if (newArr[newArr.length - 1][0] !== actualBody.endtime) {
          newArr = [...newArr, [actualBody.endtime, null]];
        }
      }
      transformedData[key] = newArr;
    });
  }
  if (actualBody.debug) {
    transformedData.debugmode = data;
  }
  console.timeEnd("servercode");
  return transformedData;
};

export const getTotalMinutesBetweenEpochs = (epoch1, epoch2) => {
  const differenceInMilliseconds = epoch2 - epoch1;
  const totalMinutes = differenceInMilliseconds / (1000 * 60);
  return totalMinutes;
};
