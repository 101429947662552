import React from "react";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import ApplicationProvider from "./lib/contexts/ApplicationContext";

export default function Root() {
  return (
    <ApplicationProvider>
      <Router>
        <App />
      </Router>
    </ApplicationProvider>
  );
}
